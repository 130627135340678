<template>
  <div class="desc-wrap"
       :class="{ 'form-desc': isForm }">
    <div class="v-center-circle">
      <div :class="[
          'v-frame',
          item.className,
          'clearfix',
          { 'v-on': index === selectIndex }
        ]"
           v-for="(item, index) in functionData"
           :key="index">
        <img :src="item.src"
             alt=""
             class="v-img" />
        <p class="v-type">
          {{ item.title }}
        </p>
        <div class="v-text"
             @mouseenter="mouseenter(index)"
             @mouseleave="mouseleave">
          <p class="v-title">
            {{ item.title }}
          </p>
          <p class="v-content">
            {{ item.text }}
          </p>
          <i></i>
        </div>
      </div>
      <div v-if="!isForm">
        <img v-for="(item, index) in imgData"
             :key="index"
             :src="item.src"
             alt=""
             :class="[
            'v-center-img',
            item.className,
            { 'v-on': index === selectIndex }
          ]" />
      </div>
      <div class="img-center"
           v-if="isForm">
        <img v-for="(item, index) in imgData"
             :key="index"
             :src="item.src"
             alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgData: {
      type: Array,
      default () {
        return []
      }
    },
    functionData: {
      type: Array,
      default () {
        return []
      }
    },
    isForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectIndex: 0,
      timer: null
    }
  },
  mounted () {
    this.interval()
  },
  methods: {
    interval () {
      const len = this.functionData.length
      this.timer = setInterval(() => {
        this.selectIndex <= len - 1 && this.selectIndex++
        this.selectIndex > len - 1 && (this.selectIndex = 0)
      }, 3000)
    },
    mouseenter (index) {
      clearInterval(this.timer)
      this.selectIndex = index
    },
    mouseleave () {
      this.interval()
    }
  }
}
</script>

<style lang="less" scoped>
.form-desc {
  .v-center-circle {
    background: none;
    .v-frame {
      &.v-frame4 {
        left: 50%;
        top: 580px;
        transform: translateX(-50%);
        .v-type {
          background: #605de6;
        }
        .v-type,
        img {
          position: relative;
          left: -50%;
          transform: translateX(50%);
        }
        .v-text {
          text-align: center;
        }
        &:hover,
        &.v-on {
          i {
            width: 17px;
            height: 18px;
            background: url('../../../../assets/images/form/edit-form-hover.png')
              no-repeat;
            background-size: contain;
          }
        }
      }
      &.v-frame5 {
        top: 380px;
        left: -275px;
        .v-type {
          background-color: #5ed3de;
        }

        &:hover {
          i {
            background-position: -124px -24px;
          }
        }

        &.v-on {
          i {
            background-position: -124px -24px;
          }
        }
      }
    }
    .img-center {
      width: 668px;
      height: 668px;
      img {
        width: 100%;
      }
    }
  }
}
.v-center-circle {
  width: 668px;
  height: 668px;
  position: relative;
  margin: 0 auto;
  background: url('../../../../assets/images/doc/advantage_circle@2x.png')
    no-repeat center center;
  background-size: cover;

  .flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .right-text {
    text-align: left;
    padding: 20px 20px 20px 50px;
  }

  .v-frame {
    width: 362px;
    height: 210px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.v-frame1:hover,
    .v-frame1.v-on,
    .v-frame4:hover,
    .v-frame4:v-on {
      background-position: -31px -132px;
    }

    &.v-frame1 {
      top: 65px;
      left: -275px;

      .v-type {
        background-color: #c3de5e;
      }

      &:hover {
        background-position: -31px -132px;

        i {
          background-position: -53px -24px;
        }
      }

      &.v-on {
        i {
          background-position: -53px -24px;
        }
      }
    }

    &.v-frame2 {
      .flex;
      top: 65px;
      right: -275px;

      .v-type {
        background-color: #1e89e4;
      }

      .v-text {
        .right-text;
      }

      i {
        left: 25px;
      }

      &:hover {
        i {
          background-position: -89px -24px;
        }

        .v-text {
          background-position: -439px -132px;
        }
      }

      &.v-on {
        i {
          background-position: -89px -24px;
        }

        .v-text {
          background-position: -439px -132px;
        }
      }
    }

    &.v-frame3 {
      .flex;
      top: 380px;
      right: -275px;

      .v-type {
        background-color: #ff985f;
      }

      .v-text {
        .right-text;
      }

      i {
        left: 25px;
      }

      &:hover {
        .v-text {
          background-position: -439px -132px;
        }

        i {
          background-position: -160px -24px;
        }
      }

      &.v-on {
        .v-text {
          background-position: -439px -132px;
        }

        i {
          background-position: -160px -24px;
        }
      }
    }

    &.v-frame4 {
      top: 380px;
      left: -275px;

      .v-type {
        background-color: #5ed3de;
      }

      &:hover,
      &.v-on {
        i {
          background-position: -124px -24px;
        }
      }
    }

    .v-img {
      width: 78px;
    }

    .v-type {
      width: 77px;
      height: 23px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      color: #fff;
      border-radius: 21px;
    }

    .v-text {
      width: 304px;
      height: 114px;
      padding: 20px 50px 20px 20px;
      margin-top: 3px;
      background: url('../../../../assets/images/doc/advantage_bg.png')
        no-repeat -31px -14px;
      text-align: right;
      position: relative;

      i {
        display: block;
        width: 17px;
        height: 18px;
        position: absolute;
        right: 25px;
        top: 26px;
        background: url('../../../../assets/images/doc/advantage_light.png')
          no-repeat -17px -24px;
      }

      .v-content {
        margin-top: 5px;
        font-size: 12px;
        color: #555;
        font-weight: 500;
      }

      .v-title {
        font-size: 18px;
        color: #555;
      }
    }
  }

  .v-img1 {
    width: 359px;
    height: 328px;
    margin-top: -164px;
    margin-left: -179.5px;
  }

  .v-img2 {
    width: 384px;
    height: 316px;
    margin-top: -158px;
    margin-left: -192px;
  }

  .v-img4 {
    width: 352px;
    height: 331px;
    margin-top: -176px;
    margin-left: -165.5px;
  }

  .v-img3 {
    width: 394px;
    height: 380px;
    margin-top: -190px;
    margin-left: -197px;
  }

  .v-center-img {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    opacity: 0;

    &.v-on {
      opacity: 1;
      transition: opacity 1.5s ease-in-out;
    }
  }
}
</style>
